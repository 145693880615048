<template>
  <v-card outlined>
    <v-card-title>
      <v-row align="start" dense>
        <v-col>
          <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedStartFormatted"
                label="Crawler dalla data"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                clearable
                hide-details
                @click:clear="start = null"
              ></v-text-field>
            </template>
            <v-date-picker v-model="start" no-title scrollable @input="menuStart = false" @change="aggiornaTask"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="menuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedEndFormatted"
                label="Crawler alla data"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                clearable
                hide-details
                @click:clear="end = null"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end" no-title scrollable @input="menuEnd = false" @change="aggiornaTask"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="aggiornaTask" :loading="loading" title="Legge il riepilogo del Crawler"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col>
      </v-row>         
    </v-card-title>
    <v-card-subtitle>
      <span @click="resetPeriodo(item.task)" class="text-decoration-underline mouse-link">{{ periodo(item.task) }}</span>
    </v-card-subtitle>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Metrica</th>
                <th class="text-right">Valore</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Totali</td><td class="text-right">{{ item.task.totali }}</td></tr>
              <tr><td>Positivi</td><td class="text-right">{{ item.task.positivi.true }}</td></tr>
              <tr><td>Parziali crw Rosso</td><td class="text-right">{{ item.task.parzialiRosso.true }}</td></tr>
              <tr><td>Parziali crw Verde</td><td class="text-right">{{ item.task.parzialiVerde.true }}</td></tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <apexchart type="pie" :options="chartOptions" :series="series" ></apexchart>
      </v-col>      
    </v-row>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    props: {
      item: Object,
      index: Number
    },
    data (vm) {
      return {
        start: null,
        end: moment().utcOffset(0, true).format('YYYY-MM-DD'), 
        menuStart: false,
        menuEnd: false,
        startFormatted: vm.formatDate(moment().add(-15, 'days').utcOffset(0, true).format('YYYY-MM-DD')),
        endFormatted: vm.formatDate(moment().utcOffset(0, true).format('YYYY-MM-DD')),
        loading: false,
        // series: [44, 55, 13, 43, 22],
        chartOptions1: {
          chart: {
            width: 380,
            type: 'pie'
          },
          // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        } 
      }
    },
    computed: {
      computedStartFormatted () {
        return this.formatDate(this.start)
      },
      computedEndFormatted () {
        return this.formatDate(this.end)
      },
      series() {
        return [
          // this.item.task.totali,
          this.item.task.positivi.true,
          this.item.task.parzialiVerde.true,
          this.item.task.parzialiRosso.true
        ]
      },
      seriesLabels() {
        return [
          // 'Totali',
          'Positivi',
          'Parziali crw Verde',
          'Parziali crw Rosso'
        ]
      },    
      chartOptions() {
        return {
          pie: {
            customScale: 1.4
          },
          chart: {
            width: 380,
            type: 'pie'
          },
          labels: this.seriesLabels,
          legend: {
            show: true,
            position: 'bottom'
          }
        }
      }       
    },
    watch: {
      item(val) {
        this.loading = false
      },
      start(val) {
        this.startFormatted = this.formatDate(this.start)
      },
      end(val) {
        this.endFormatted = this.formatDate(this.end)
      }
    },
    mounted() {
      this.aggiornaPeriodo(this.item.task)
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },      
      periodo(task) {
        switch(task.periodo.pId) {
          case 0:
            return 'Ultima settimana'
          case 1:
            return 'Ultimi trenta giorni'
          default:
            return 'Tutto il periodo'
        }
/*         let start = task.periodo.start ? moment(task.periodo.start).format('DD/MM/YYYY') : '-'
        let end = task.periodo.end ? moment(task.periodo.end).format('DD/MM/YYYY') : '-'
        return `Periodo: ${start} - ${end}` */
      },
      resetPeriodo(task) {
        this.aggiornaPeriodo(task)
        this.aggiornaTask()
      },
      aggiornaPeriodo(task) {
        switch(task.periodo.pId) {
          case 0:
            this.start = moment().utcOffset(0, true).add(-7, 'days').startOf('day').format('YYYY-MM-DD')
            this.end = null
            break
          case 1:
            this.start = moment().utcOffset(0, true).add(-30, 'days').startOf('day').format('YYYY-MM-DD')
            this.end = null
            break
          default:
            this.start = null
            this.end = null
        }
      },
      aggiornaTask() {
        this.loading = true
        const payload = {
          pId: this.index,
          // tipologia: this.item.tipologia,
          filtri: {
            start: this.start,
            end: this.end,
            tipologia: this.item.tipologia
          }
        }
        this.$emit('aggiorna', payload)
      }
    }
  }
</script>

<style>
  .mouse-link {
    cursor: pointer
  }
</style>