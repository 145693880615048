<template>
  <div>
    <h2 class="mt-5">Statistica task asseverazione</h2>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-card v-for="(tipologia, idx) in tipologie" :key="idx" class="mx-auto mt-5">
      <v-card-title>{{ tipologia }}</v-card-title>
      <v-row>
        <v-col v-for="(item, index) in statisticaTask.filter(x => x.tipologia === tipologia)" :key="index" cols="4">
          <statistica-task :item="item" :index="index" @aggiorna="aggiornaTask"/>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import StatisticaTask from '../elementi/statisticaTask'
  import selfapi from '@/services/selfapi.js'

  export default {
    components: {
      StatisticaTask
    },
    data () {
      return {
        totaleEnti: 0,
        loading: false,
        filtri: null,
        statisticaTask: [],
        tipologie: ['Scuole', 'Comuni'],
        periodi: []
      }
    },
    async mounted () {
      this.periodi = []
      for (let tipo of this.tipologie) {
        for (let p = 0; p < 3; p++) {
          let filtro = {
            tipologia: tipo,
            end: null
          }
          switch(p) {
            case 0:
              filtro.start = moment().utcOffset(0, true).add(-7, 'days').startOf('day').format('YYYY-MM-DD')
              break
            case 1:
              filtro.start = moment().utcOffset(0, true).add(-30, 'days').startOf('day').format('YYYY-MM-DD')
              break
            default:
              filtro.start = null
          }        
          this.periodi.push(filtro)
        }
      }
      await this.getDataFromApi()
    },
    methods: {
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          this.statisticaTask = await selfapi.getStatisticaTask(this.periodi)
        } catch(err) {
        } finally {
          this.loading = false
        }
      },
      async aggiornaTask(payload) {
        try {
          this.loading = true
          this.periodi[payload.pId] = payload.filtri
          this.statisticaTask = await selfapi.getStatisticaTask(this.periodi)
        } catch(err) {
        } finally {
          this.loading = false
        }
      }
    }    
  }
</script>